import React, { Ref, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";

import { DISPENSARY_DEFAULT_MAX_DISTANCE } from "constants/dispensary";
import { useStrainPageContext } from "context/StrainPageContext";
import { getCoordinates } from "redux/selectors/location";
import { fetchNearbyDispensaries } from "utils/strains/fetchNearbyDispensaries";

import StrainDispensariesCarousel from "./StrainDispensariesCarousel";

const INITIAL_STATE = [{}, {}, {}];

const StrainDispensariesSection = () => {
  const { strain } = useStrainPageContext();

  const [dispensaries, setDispensaries] = useState(INITIAL_STATE);
  const [distanceUsed, setDistanceUsed] = useState<number>();

  const coordinates = useSelector(getCoordinates);

  useEffect(() => {
    setDispensaries(INITIAL_STATE);
    setDistanceUsed(undefined);
  }, [strain.slug]);

  const fetchData = async (inView: boolean) =>
    inView &&
    fetchNearbyDispensaries(
      strain,
      coordinates,
      DISPENSARY_DEFAULT_MAX_DISTANCE,
    ).then((result) => {
      setDistanceUsed(result.distanceUsed);
      setDispensaries(result.dispensaries);
    });

  return (
    <InView
      rootMargin="125px"
      triggerOnce={true}
      key={strain.slug}
      onChange={(inView) => fetchData(inView)}
    >
      {({ ref }: { ref: Ref<HTMLDivElement> }) => (
        <div id="strain-dispensaries" ref={ref}>
          {dispensaries.length > 0 && (
            <StrainDispensariesCarousel
              dispensaries={dispensaries}
              distanceUsed={distanceUsed}
            />
          )}
        </div>
      )}
    </InView>
  );
};

export default StrainDispensariesSection;
