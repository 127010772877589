import React from "react";

import EllipseIcon from "components/Icons/ellipse_filled.svg";
import IconTile from "components/IconTile";

type Props = {
  title: string;
  link: string;
  eventLabel: string;
  icon: React.ReactNode;
};

const negatives = [
  "Anxious",
  "Dizzy",
  "Dry eyes",
  "Dry mouth",
  "Headache",
  "Paranoid",
];

const StrainSensationTile = ({ title, link, eventLabel, icon }: Props) => (
  <IconTile
    title={title}
    link={link}
    eventCategory="Strain Effects"
    eventLabel={eventLabel}
    trackImpression={false}
  >
    <div className="relative">
      <div className="relative" style={{ zIndex: 1 }}>
        {icon}
      </div>
      {negatives.includes(title) ? (
        <span
          className="absolute opacity-25 text-error-60"
          style={{
            right: "20px",
            top: "10px",
          }}
        >
          <EllipseIcon width="27" height="20" />
        </span>
      ) : (
        <span
          className="absolute opacity-25 text-green-60"
          style={{
            right: "20px",
            top: "10px",
          }}
        >
          <EllipseIcon width="27" height="20" />
        </span>
      )}
    </div>
  </IconTile>
);

export default StrainSensationTile;
